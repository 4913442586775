import React, { useState } from 'react';
import { customMetricsByLocation } from "../../logic/eligible";
import FivePointScale from './five_point_scale.js';

const CustomMetricsInLocation = ({
  concepts, data, result, goForward, location, survey, productDescriptionComponent, forceImage
}) => {
  const [ index, setIndex ] = useState(0);

  const goNextConceptCustomMetricsStart = () => {
    if (index < concepts.length - 1 && !survey) {
      setIndex(index + 1);
    } else {
      goForward();
    }
  };

  const scorings = customMetricsByLocation(survey ? data.survey_metrics : data.scorings, location);

  return (
    <FivePointScale
      key={ index }
      concept={ concepts[index] }
      questions={ scorings }
      result={ result }
      nextStep={ () => { goNextConceptCustomMetricsStart(); } }
      survey={ survey }
      forceImage={ forceImage }
      productDescriptionComponent={ productDescriptionComponent }
    />
  );
};

export default CustomMetricsInLocation;
